<template>
  <div>
    <input :value="$route.query.text" @change="onChangeText" />
    <pre>{{ $route.query.text }}</pre>
  </div>
</template>
<script>
export default {
  methods: {
    onChangeText(e) {
      if (e.target.value) {
        this.$router.push({ query: { text: e.target.value } });
      } else {
        this.$router.push({ path: this.$route.path });
      }
    },
    clearStorage() {
      localStorage.removeItem("text");
    },
  },
};
</script>